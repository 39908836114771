<template>
	<div id="app">
		<Loading v-show="loading"></Loading>
		<transition :name="transitionName">
			<router-view />
		</transition>
		
		
		<div>
		  <div @click="onKefuClick"  class="draggable whs-service" 
			style="transform: translate(0px, 0px); z-index: auto; user-select: auto; width: 64px; height: 64px;">
		    <div class="whs-service-btn"></div>
		  </div>
		  
		  <van-popup
		    v-model="kefuShow"
		    closeable
		    position="bottom"
			:close-on-click-overlay="false"
			@click-close-icon="onkefuClose"
			@close="onvtClose"
		    :style="{ height: '85%' }"
		  >
		  
		  <iframe v-if="configInfo" :src="configInfo.CustomerServiceLink"  width="100%" height="100%" scrolling="auto"></iframe>

		  </van-popup>
		</div>
	</div>
</template>
<script>
	import Loading from "./components/loading";
	import {
		mapState,
		mapMutations
	} from 'vuex'
	import {
		apiGetIslogin
	} from '@/request/api'; //api接口
	export default {
		name: 'App',
		data() {
			return {
				kefushow: false,
				transitionName: 'fadeIn'
			}
		},
		components: {
			Loading
		},
		computed: {
			...mapState(['loading', 'kefuShow','configInfo'])
		},
		created() {
			this.$store.dispatch("getConfigInfo");
			
		},
		
		mounted() {
			this.getConfigInfo();
			document.title = ''
			this.getUrlKey();
			this.localSocket();
		},
		watch: {
			//使用watch 监听$router的变

		},
		methods: {
			...mapMutations(['setConfigInfo']),
			localSocket() {
			  let that = this;
			  if ("WebSocket" in window) {
			    console.log("您的浏览器支持 WebSocket!");
			    
			    that.ws = new WebSocket(`wss://stream.binance.com/stream`);
			    that.$global.setWs(that.ws);
			    that.ws.onopen = () => {console.log('开始连接')};
			    //that.onopen(); 这个地方未定义是会报错，所以我写成了  that.ws.onopen = function() {console.log('开始连接')};
			
			    that.ws.onclose = ()=> {
			      // 关闭 websocket
			      console.log("连接已关闭...");
			      setTimeout(() => {
			        that.localSocket();
			      }, 2000);
			    };
			  } else {
			    // 浏览器不支持 WebSocket
			    console.log("您的浏览器不支持 WebSocket!");
			  }
			},
			onKefuClick() {
				this.$store.commit('setKefuShow')
				// this.kefushow = !this.kefushow;
			},
			onkefuClose() {
				this.$store.commit('setKefuHide')
			},
			onvtClose() {
				this.$store.commit('setKefuHide')
			},
			getUrlKey() {
				var url = window.location.href; //获取当前url
				var dz_url = url.split("#")[0]; //获取#/之前的字符串


				var dz_url = dz_url.split("?");

				if (dz_url.length > 1) {
					var cs = dz_url[1]; //获取?之后的参数字符串
					var cs_arr = cs.split("&"); //参数字符串分割为数组
					var cs = {};

					for (var i = 0; i < cs_arr.length; i++) {
						//遍历数组，拿到json对象
						cs[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
					}
					localStorage.setItem("inviter_code",cs.code);//保存数据

					console.log('code', cs.code);
				}
			},
			getConfigInfo() {
				apiGetIslogin({}).then(res => {
					if (res.code != 0) {
						
						
						let noLoginArr = ['login', 'regist'];
						
						if (noLoginArr.indexOf(this.$route.name) == -1) {
							this.$router.push('/login')
						}
						
					}
				})
			},
		}
	}
</script>

<style>
	
	@import 'vant/lib/index.css';
	@import '/src/static/css/app.css';

</style>
